async function generateRandomString() {
  const randomNumber = Math.floor(Math.random() * 10000000000000000);
  const randomString = randomNumber.toString().padStart(16, '0');
  return randomString;
}

export async function createSessionId() {
    try {
        const existingSessionIdKey = localStorage.getItem('sessionIdKey');
        if (existingSessionIdKey !== null) {
            return existingSessionIdKey;
        } else {
            const randomString = await generateRandomString()
            localStorage.setItem('sessionIdKey', randomString);
            return randomString;
        }
    } catch (error) {
      console.log("Local storage is disabled or full.")
    }
}
