import './App.css';
import React, { useState, useEffect } from 'react';
import ChatBot from './components/Chatbot';
import { createSessionId } from './helpers/createSessionId';
import LanguageSelector from './components/LanguageSelector';

const App = () => {

  // Setup and update session ID key
  const [sessionIdKey, setSessionIdKey] = useState(null);
  useEffect(() => {
    async function setSessionID() {
      const sessionID = await createSessionId();
      setSessionIdKey(sessionID);
    }
    setSessionID();
  }, []);

  // Set and update language via languageSelector
  const [lang, setLang] = useState('Spanish');
  const handleOptionChange = (event) => {
    setLang(event.target.value);
  };
  // TODO: consider storing language in local storage,
  // to prevent language reset on refresh

  return (
    <div className="App">
      <div className="App-header">
        <LanguageSelector lang={lang} handleOptionChange={handleOptionChange} />
      </div>
      <ChatBot language={lang} sessionId={sessionIdKey} />
    </div>
  );
};

export default App;

