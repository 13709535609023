import React from "react";
import { ReactComponent as UserProfile } from '../../assets/userIcon.svg';
import './Chat.css';
import Loader from '../Loader';

function Chat({ messages, formSubmitted }) {
  return (
    <div className="chat-messages-container">
      {messages.map((message) => (
        message.role === "assistant" ? (
        <div key={message.id} className="chat-single-bot-message-container">
          <div className="avatar-bot-container">
            <div className="avatar">
              <div className="avatar-text">
              B
              </div>
            </div>
          </div>
          <div
            className={`chat-${message.role}-message`}
          >
            {message.content}
          </div>
          <div className="message-arrow-bot">
            {/* TODO */}
          </div>
        </div>
        ) : (
          <div key={message.id} className="chat-single-user-message-container">
            <div className={`chat-${message.role}-message`}>
              {message.content}
            </div>
            <div className="message-arrow-user">
              {/* TODO */}
            </div>
            <div className="avatar-user-container">
              <div className="avatar">
                <div className="avatar-text">
                  U
                  {/* <UserProfile /> */}
                </div>
              </div>
            </div>
        </div>
        )
      ))}
      {formSubmitted && (
        <div key="1000" className="chat-single-bot-message-container">
          <div className="avatar-bot-container">
            <div className="avatar">
              <div className="avatar-text">
              B
              </div>
            </div>
          </div>        
          <Loader />
          <div className="message-arrow-bot">
            {/* TODO */}
          </div>
        </div>
      )}
    </div>
  );
}

export default Chat;