
function parseMessagesForLLM(messages) {

    const messagesForLLM = [];
    // TODO: add a suitably helpful system message
    // messagesForLLM.push({
    //     "role": "system",
    //     "content": "You are a helpful assistant who answers in the language you are spoken to in."
    // })

    messages.forEach(message => {
        messagesForLLM.push({
            "role": message.role,
            "content": message.content
        })
    })
    return messagesForLLM;
}

async function generateResponse(messages, sessionId) {
try {
    const messagesForLLM = parseMessagesForLLM(messages);

    // const response = await fetch("./api/generate", {
    const response = await fetch("https://language-coach.onrender.com/api/generate", {
    // const response = await fetch("http://localhost:3001/api/generate", {
        // TODO: generalise this response function call
    method: "POST",
    headers: {
        "Content-Type": "application/json",
    },
    body: JSON.stringify({ 
        messages: messagesForLLM,
        sessionIdKey: sessionId
    }),
    });

    const data = await response.json();
    if (response.status !== 200) {
    throw data.error || new Error(`Request failed with status ${response.status}`);
    }
    return data.result
    

} catch(error) {
    // Consider implementing your own error handling logic here
    console.error(error);
    alert(error.message);
}
}

export default generateResponse;
