import React from 'react';

const LanguageSelector = ({ lang, handleOptionChange }) => {
  return (
    <h3>
        <select id="options" value={lang} onChange={handleOptionChange}>
        <option value="English">I'm learning English</option>
        <option value="Spanish">Estoy aprendiendo español</option>
        <option value="German">Ich lerne Deutsch</option>
        </select>
    </h3>
  );
};

export default LanguageSelector;