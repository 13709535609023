import React, { useState, useEffect } from 'react';
import Chat from '../Chat';
import ChatInput from '../ChatInput';
import welcomeMessage from '../../constants/welcomeMessage';
import './Chatbot.css';

const ChatBot = (props) => {

  const [messages, setMessages] = useState([welcomeMessage[props.language]]);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    setMessages([welcomeMessage[props.language]]);
  }, [props.language]);

  return (
    <div>
      <div className="chat-container">
        <div className="chat-inner-container">
          <div className="chat-header"></div>
          <Chat messages={messages} formSubmitted={formSubmitted} />  
          <ChatInput 
            messages={messages} 
            setMessages={setMessages} 
            sessionId={props.sessionId}
            formSubmitted={formSubmitted}
            setFormSubmitted={setFormSubmitted}
          /> 
        </div>
      </div>
      <div className="footer">
        <h4>Created by <a href="https://www.twitter.com/ChrisLovejoy_" target="_blank">
            Chris Lovejoy
          </a>.{" "}
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSc0OQNBJAUlR7IntgsV_CJgfvJRvFKkiyivObYUR5hxVHV3zQ/viewform?usp=sf_link"
            target="_blank">
          Feedback?
          </a>
        </h4>
      </div>
    </div>
  )
};

export default ChatBot;