const welcomeMessage = {
    "English": {
        id: 1,
        content: "Hi! I'm here to help you learn English. Do you want to have a conversation about something? Are there grammatical rules you want explained? Would you like me to create some test exercises for you? Or anything else?",
        role: "assistant",
    },
    "Spanish": {
        id: 1,
        content: "¡Hola! Estoy aquí para ayudarte a aprender español. ¿Quieres tener una conversación sobre algo? ¿Hay reglas gramaticales que desees que te explique? ¿Te gustaría que cree algunos ejercicios de prueba para ti? ¿O algo más?",
        role: "assistant",
    },
    "German": {
        id: 1,
        content: "Hallo! Ich bin hier, um dir beim Deutschlernen zu helfen. Möchtest du über etwas sprechen? Gibt es grammatikalische Regeln, die du erklärt haben möchtest? Möchtest du, dass ich einige Testübungen für dich erstelle? Oder irgendetwas anderes?",
        role: "assistant",
    },
}

export default welcomeMessage;
