import { useEffect, useState } from "react";
import generateResponse from '../../api/generateResponse';
import { ReactComponent as SendIcon } from '../../assets/sendButton.svg';
import './ChatInput.css';


function ChatInput({ messages, setMessages, sessionId, formSubmitted, setFormSubmitted }) {

    const [formInput, setFormInput] = useState('');

    const handleInputChange = event => {
        setFormInput(event.target.value);
      };      

    useEffect(() => {
        if (formSubmitted) {
            async function handleResponseGeneration() {
                setFormInput("");
                const botMessage = await generateResponse(messages, sessionId);
                setFormSubmitted(false);
                setMessages(messages => 
                    [...messages, { 
                        "id": messages.length + 1,
                        "role": "assistant", 
                        "content": botMessage }]);
            }
            handleResponseGeneration();
        }
    }, [messages])

    async function handleSubmit(event) {
        event.preventDefault();
        setMessages(messages => 
            [...messages, { 
                "id": messages.length + 1,
                "role": "user", 
                "content": formInput }]);
        setFormSubmitted(true);
    }

    return (
        <div className="chat-input-container">
            <form onSubmit={handleSubmit} className="submit-form">
                <input
                type="text"
                value={formInput}
                onChange={handleInputChange}
                placeholder="Enter your message..."
                />
                <button type="submit" className="submit-button">
                    <SendIcon />
                </button>
            </form>
        </div>
    );
}
  
export default ChatInput;